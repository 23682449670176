.orbe-bubble_4 {
  top: 20%;
  left: 20%;
  border-radius: 100%;
  opacity: 0;
  width: 60%;
  height: 60%;
}

.orbe-bubble_4--1 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background: radial-gradient(circle at center top, rgba(255,255,255,0) 50%, rgba(255,255,255,0.8) 100%);
  animation: effect4_rotate 5s infinite reverse;
}

.orbe-bubble_4--2 {
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  opacity: 0.5;
  background: radial-gradient(circle at center bottom, rgba(255,255,255,0) 50%, rgba(255,255,255,0.8) 100%);
  animation: effect4_rotate 5s infinite;
}

@keyframes effect4_rotate {
  0%{
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
