/* Navbar spacing */
.navbar-spacing {
  margin-bottom: 20px;
}

/* Get token metadata button */
button {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 10px;
}

.navbar-container {
  position: fixed;
  top: 20px;
  right: 0;
  width: auto;
  background-color: rgba(255,255,255, 0.6);
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px 0 0 20px;
  box-shadow: -2px 0 5px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
}

body {
  --primary-color: #cd00b5;
  --primary-dark-color: #5d2056;
  --secondary-color: #ffffff;
  --tertiary-color: #ffffff;
  --highlight-color: rgba(255,255,255,0.6);
  --card-gradient: linear-gradient(180deg, var(--tertiary-color) 30%, var(--secondary-color) 100%);
  --border-radius: 5px;
  background: var(--background-color);
}

.App {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: var(--background-color);
  transition-duration: 2s;
  font-family: "Roboto";
}

.LuckyGem--palette_ {
  --background-color: #ffd1fa;
  --highlight-color: #ffd1fa;
}

.LuckyGem--palette_0 {
  --primary-color: #b50000;
}
.LuckyGem--palette_1 {
  --primary-color: #b57300;
}
.LuckyGem--palette_2 {
  --primary-color: #a78903;
}
.LuckyGem--palette_3 {
  --primary-color: #007100;
}
.LuckyGem--palette_4 {
  --primary-color: #01837f;
  --primary-light-color: #70a4a2;
}
.LuckyGem--palette_5 {
  --primary-color: #2893b0;
}
.LuckyGem--palette_6 {
  --primary-color: #3c3dd4;
}
.LuckyGem--palette_7 {
  --primary-color: #6d2bbd;
}
.LuckyGem--palette_8 {
  --primary-color: #b40258;
}
.LuckyGem--palette_9 {
  --primary-color: #000000;
}

.LuckyGem--palette_cold {
  --primary-light-color: #a8a8d4;
  --primary-dark-color: #391a5e;
  --secondary-color: #da7dff;
  --tertiary-color: #c6e9ff;
  --card-gradient: linear-gradient(120deg, var(--tertiary-color) 30%, var(--secondary-color) 100%);
  --background-color: #e1aaf9;
}
.LuckyGem--palette_hot {
  --primary-light-color: #a6a5a5;
  --primary-dark-color: #000;
  --secondary-color: #6cf6ff;
  --tertiary-color: #ffcaca; 
  --card-gradient: linear-gradient(120deg, var(--tertiary-color) 30%, var(--secondary-color) 100%);
  --background-color: #ffdcdc;
}

.ProfileBar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProfileBar .NearLogo {
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
  flex: 0 0 30px;
  border-radius: 100%;
  fill: var(--primary-color);
}
