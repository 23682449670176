.StatusModalBackground {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.7);
}

.StatusModal {
  position: absolute;
  top: 45%;
  left: 50%;
  z-index: 3;
  width: calc(100% - 60px);
  max-width: 600px;
  max-height: 400px;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  transform: translate(-50%, -50%);
  font-family: 'Roboto';
  border: 0 none;
  border-radius: 20px;
  background: var(--card-gradient);
  box-shadow: 0 2px 5px var(--primary-color);
}

.StatusModalBorder {
  margin: -35%;
  padding: 30px;
  width: 170%;
  height: 180%;
  opacity: 0.7;
  background-color: rgb(255, 255, 255);
}
.StatusModalBorder--shine {
  animation: rotateGrad 2s infinite linear;
  background: linear-gradient(0deg, rgba(226, 3, 3, 1) 0%, rgba(230, 146, 2, 1) 10%, rgba(222, 182, 2, 1) 21%, rgba(4, 230, 4, 1) 32%, rgba(3, 228, 221, 1) 44%, rgba(54, 196, 235, 1) 56%, rgba(72, 73, 245, 1) 71%, rgba(142, 59, 242, 1) 81%, rgba(237, 2, 116, 1) 91%, rgba(236, 2, 115, 1) 100%);
}

.StatusModalContent {
  position: absolute;
  top: 0;
  left: 0;
  margin: 2px;
  padding: 20px;
  text-align: center;
  height: calc(100% - 44px);
  width: calc(100% - 44px);
  background-color: #fff;
  border-radius: 20px;
}

.StatusModalHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 20px 10px;
  font-size: 20px;
  font-weight: 550;
}

.StatusModalBody {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 16px;
  line-height: 28px;
}
.StatusModalBody p {
  margin: 5px;
}
.StatusModalBody .orbe {
  margin: 0 0 5px;
}

.StatusModal-drawnedBet {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: flex-start;
  width: 120px;
}

.StatusModal-drawnedBetText {
  flex: 1 1 50%;
  text-align: right;
  padding: 3px;
}
.StatusModal-drawnedBetNumber {
  flex: 0 0 auto;
  padding: 3px;
}
.StatusModal-betNumber {
  display: inline-block;
}
.StatusModal-betNumber--wrong {
  background-color: rgba(226, 3, 3, 0.6);
}

.StatusModalClose {
  position: absolute;
  top: 6ṕx;
  right: 10px;
  justify-self: flex-end;
  align-self: center;
  padding: 10px;
  color: #000;
  font-size: 20px;
  background-color: transparent;
  border-radius: 100%;
  cursor: pointer;
}

@keyframes rotateGrad {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}