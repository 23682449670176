.orbe-back--effect_1 {
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: radial-gradient(circle, rgba(255,255,255,0) 30%, rgba(255,255,255,0.7) 100%);
}

.orbe-bubble_1 {
  left: 50%;
  top: 50%;
  animation: bubble_1 0.5s infinite normal ease;
}

.orbe-bubble_1--1 {
  top: 0%;
  left: -5%;
}

.orbe-bubble_1--2 {
  top: 0%;
  left: 60%;
  animation-delay: 0.1s;
}

.orbe-bubble_1--3 {
  top: 50%;
  left: 100%;
  animation-delay: 0.2s;
}

.orbe-bubble_1--4 {
  top: 100%;
  left: 80%;
  animation-delay: 0.3s;
}

.orbe-bubble_1--5 {
  top: 80%;
  left: 0%;
  animation-delay: 0.3s;
}

@keyframes bubble_1 {
  0% {
    left: 50%;
    top: 50%;
    opacity: 0.2;
    transform: scale(1);
  }
  100%{
    opacity: 1;
    transform: scale(1.1);
  }
}
