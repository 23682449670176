.Footer {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    background-color: rgba(255,255,255, 0.6);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-size: 11px;
}

@media (min-width: 400px) {
    .Footer {
        font-size: 13px;
    }
}
.Footer-content {
    flex: 0 0 auto;
}