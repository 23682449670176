
.orbe-bubble_8 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
  --effct8-duration: 5s;
  background: radial-gradient(circle, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0) 100%);
}

.orbe-bubble_8--1 {
  animation: effect8 var(--effct8-duration) infinite;
}

.orbe-bubble_8--2 {
  top: -25%;
  left: -25%;
  animation: effect8 var(--effct8-duration) 1s infinite;
}

.orbe-bubble_8--3 {
  top: 25%;
  left: 25%;
  animation: effect8 var(--effct8-duration) 2s infinite;
}

.orbe-bubble_8--4 {
  top: -25%;
  left: 25%;
  animation: effect8 var(--effct8-duration) 3s infinite;
}

.orbe-bubble_8--5 {
  top: 25%;
  left: -25%;
  animation: effect8 var(--effct8-duration) 4s infinite;
}

@keyframes effect8 {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.4;
  }
  10% {
    opacity: 0;
  }
  15% {
    opacity: 0.4;
  }
  20% {
    opacity: 0;
  }
  25% {
    opacity: 0.4;
  }
  30% {
    opacity: 0;
  }
}