
.orbe-back--effect_0 {
  background: radial-gradient(circle, rgba(255,255,255,0) 20%, rgba(255,255,255,0.4) 40%, rgba(250,255,255,0) 60%);
  animation: lightMove 10s infinite alternate linear;
}

.orbe-bubble_0 {
  animation: bubble_0 3s infinite normal ease;
}

.orbe-bubble_0--1 {
  left: 25%;
}

.orbe-bubble_0--2 {
  left: 40%;
  animation-delay: 2.5s;
}

.orbe-bubble_0--3 {
  left: 50%;
  animation-delay: 0.75s;
}

.orbe-bubble_0--4 {
  left: 60%;
  animation-delay: 1.75s;
}

.orbe-bubble_0--5 {
  left: 70%;
  animation-delay: 0.45s;
}

@keyframes lightMove {
  from {
    top: -90%;
    width: 150%;
    height: 200%;
  }
  to {
    top: -60%;
    width: 200%;
    height: 180%;
  }
}

@keyframes bubble_0 {
  0%{
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0%;
    opacity: 0;
  }
}
