

.PrizesList {
    display: flex;
    max-width: 85%;
    margin-bottom: 20px;
    flex-flow: column nowrap;
    color: var(--primary-dark-color);
    font-size: 12px;
}

@media (min-width: 400px) {
    .PrizesList {
        font-size: 16px;
    }
}
@media (min-width: 800px) {
    .PrizesList {
        font-size: 18px;
    }
}

.PrizeList-instructions{
    font-size: 11px;
    margin: 0 0 10px 0;
}

.PrizeList-item {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 100%;
    margin: 2px;
}

.PrizeList-description {
    flex: 0 0 auto;
    margin-right: 10px;
}

.PrizeList-prize {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2px 4px;
    border-radius: var(--border-radius);
    flex: 1 1 auto;
    text-align: right;
    color: var(--primary-color);
    background-color: var(--highlight-color);
}