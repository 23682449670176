.orbe-icon svg {
    fill: rgba(255,255,255,0.8);
}

.Gem--palette_ .orbe-icon svg {
    fill: var(--primary-color);
}

.orbe-icon-question {
    width: 60px;
    margin-top: 7px;
    margin-left: 0px;
    
}

.orbe-icon-bull {
    width: 80px;
    margin-top: 7px;
    margin-left: -4px;
}

.orbe-icon-pig {
    width: 70px;
    margin-top: 5px;
}

.orbe-icon-bear {
    width: 71px;
    margin-top: -2px;
    margin-left: -2px;
}

.orbe-icon-snake {
    width: 50px;
    margin-top: 6px;
}

.orbe-icon-hummingbird {
    width: 65px;
    margin-top: 5px;
    margin-left: 4px;
}

.orbe-icon-dolphin {
    width: 80px;
    margin-top: -3px;
    margin-left: -5px;
}

.orbe-icon-rabbit {
    width: 79px;
    margin-top: 7px;
    margin-left: 3px;
}

.orbe-icon-chameleon {
    width: 80px;
    margin-top: 6px;
}

.orbe-icon-dog {
    width: 70px;
}

.orbe-icon-cat {
    width: 80px;
    margin-left: 4px;
}