
.orbe-bubble_3 {
  top: 20%;
  left: 20%;
  border-radius: 0;
  opacity: 1;
  width: 60%;
  height: 60%;
  animation: bubble_3 5s infinite linear;
  background: radial-gradient(circle, rgba(255,255,255,0) 20%, rgba(255,255,255,0.15) 100%);
}

.orbe-bubble_3--2 {
  animation: bubble_3_2 5s 3s infinite reverse;
  animation-delay: 2.5s;
  transform: rotate(45deg);
}

.orbe-bubble_3--3 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255,255,055,0) 50%, rgba(255,255,255,0.8) 100%);
}

.orbe-bubble_3--4 {
  opacity: 0;
}
    
.orbe-bubble_3--5 {
      opacity: 0;
}

@keyframes bubble_3 {
  95%{
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bubble_3_2 {
  95%{
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}




