
.orbe-back--effect_2 {
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: radial-gradient(circle, rgba(255,255,255,0) 30%, rgba(255,255,255,0.5) 100%);
}

.orbe-bubble_2 {
  left: 0;
  top: 50%;
  width: 100%;
  height: 10px;
  border-radius: 0;
  animation: bubble_2 5s infinite normal;
}

.orbe-bubble_2--2 {
  animation-delay: 1s;
}
    
.orbe-bubble_2--3 {
  animation-delay: 2s;
}

.orbe-bubble_2--4 {
  animation-delay: 3s;
}

.orbe-bubble_2--5 {
  animation-delay: 4s;
}

@keyframes bubble_2 {
  0% {
    height: 2px;
    opacity: 0.6;
    top: 100%;
  }
  100% {
    top: 55%;
    height: 6px;
    opacity: 0;
  }
}
