
.Card {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    padding: 30px;
    margin: 10px 10px 10% 10px;
    width: calc(100% - 80px);
    max-width: 350px;
    font-family: 'Roboto';
    border: 0 none;
    border-radius: 20px;
    background: var(--card-gradient);
    box-shadow: 0 2px 5px var(--primary-color);
    transition-duration: 2s;
}

.CardRow {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1 1 auto;
}

.CardCol {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    padding: 5px;
}

.CardCol.small {
    flex: 0 0 auto;
}

.BetButton {
    width: 100%;
    border-radius: var(--border-radius);
}

.CardTitle {
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 10px;
    color: var(--primary-dark-color);
}