
.orbe-bubble_7 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
}

.orbe-bubble_7--1 {
  opacity: 0.2;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
}

.orbe-bubble_7--2 {
  opacity: 0.2;
  top: -0%;
  width: 140%;
  height: 90%;
  left: -20%;
  animation: effect7 5s infinite;
}

.orbe-bubble_7--3 {
  opacity: 0.2;
  top: 10%;
  width: 140%;
  height: 90%;
  left: -20%;
  animation: effect7 5s infinite reverse;
}

@keyframes effect7 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}