.orbe-bubble_6 {
  top: 0;
  left: 40%;
  width: 10%;
  height: 100%;
  border: 4px solid rgba(255,255,255,0.1);
  background-color: transparent;
  border-radius: 0;
  opacity: 0;
}

.orbe-bubble_6--1 {
  opacity: 1;
  animation: effect6 2s infinite alternate;
}

.orbe-bubble_6--2 {
  opacity: 1;
  animation: effect6 2s infinite alternate-reverse;
}

.orbe-bubble_6--3 {
  opacity:1;
  transform: rotate(45deg);
  animation: effect6 2s 1s infinite alternate;
}

.orbe-bubble_6--4 {
  opacity:1;
  transform: rotate(-45deg);
  animation: effect6 2s 1s infinite alternate-reverse;
}

@keyframes effect6{
  0% {
    top: -50%;
    left: 50%;
    width: 0%;
    height: 200%;
    transform: rotate(0);
    background-color: rgba(255,255,255,0.1);
    opacity: 0.5;
  }
  50%{
    opacity: 1;
    background-color: rgba(255,255,255,0);
  }
  100% {
    top: 50%;
    left: -50%;
    width: 200%;
    height: 0%;
    background-color: rgba(255,255,255,0.1);
    opacity: 0.5;
  }
}
