
.orbe-back--effect_9 {
  background: radial-gradient(circle, rgba(255,255,255,0) 20%, rgba(255,255,255,0.4) 40%, rgba(250,255,255,0) 60%);
  animation: lightMove9 10s infinite alternate linear;
}

.orbe-bubble_9 {
  animation: bubble_9 3s infinite normal ease;
}

.orbe-bubble_9--1 {
  left: 25%;
}

.orbe-bubble_9--2 {
  left: 40%;
  animation-delay: 2.5s;
}

.orbe-bubble_9--3 {
  left: 50%;
  animation-delay: 0.75s;
}

.orbe-bubble_9--4 {
  left: 60%;
  animation-delay: 1.75s;
}

.orbe-bubble_9--5 {
  left: 70%;
  animation-delay: 0.45s;
}

@keyframes lightMove9 {
  from {
    top: 10%;
    width: 200%;
    height: 200%;
  }
  to {
    top: 15%;
    width: 250%;
    height: 180%;
  }
}

@keyframes bubble_9 {
  0%{
    top: 5%;
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 100%;
    opacity: 0;
  }
}
