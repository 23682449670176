.BetInput {
    margin: 10px 0 0;
    padding: 5px;
    font-family: 'Roboto';
    font-size: 26px;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
}
.BetInput-number {
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    width: 20px;
    margin: 2px;
    color: var(--primary-color);
    text-align: center;
    font-size: 24px;
}

.BetInput-number:focus-visible {
    outline: 0.5px solid var(--primary-color);
}

.BetInput-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
}