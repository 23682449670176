
.Gem {
    display: inline-block;
}

.orbe {
  position: relative;
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  background-color: var(--primary-color);
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.34);
}

.Gem--palette_ .orbe{
  background-color: #fff;
  box-shadow: none;
  outline: 1px solid var(--primary-color);
}

.orbe-back {
  position: absolute;
  flex: 1 1 100%;
  top: -110%;
  width: 200%;
  height: 200%;
}

.orbe-bubble {
  position: absolute;
  top: 80%;
  left: 25%;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.5);
}


