
.orbe-bubble_5 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  opacity: 0;
}

.orbe-bubble_5--1 {
  background: radial-gradient(circle, rgba(255,255,255,0) 30%, rgba(255,255,255,1) 100%);
  animation: effect5_pulse 2s infinite;
}

.orbe-bubble_5--2 {
  background: radial-gradient(circle, rgba(255,255,255,0) 30%, rgba(255,255,255,1) 100%);
  animation: effect5_pulse 2s 1s infinite;
}

.orbe-bubble_5--3 {
  opacity: 1;
  background: radial-gradient(circle, rgba(255,255,255,0) 60%, rgba(255,255,255,0.6) 100%);
}

@keyframes effect5_pulse {
  0% {
    transform: scale(0);
    opacity: 0.3;
  }
  100% {
    opacity: 0.4;
    transform: scale(2.5);
  }
}
