@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap);
:root, html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Navbar spacing */
.navbar-spacing {
  margin-bottom: 20px;
}

/* Get token metadata button */
button {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 10px;
}

.navbar-container {
  position: fixed;
  top: 20px;
  right: 0;
  width: auto;
  background-color: rgba(255,255,255, 0.6);
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px 0 0 20px;
  box-shadow: -2px 0 5px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
}

body {
  --primary-color: #cd00b5;
  --primary-dark-color: #5d2056;
  --secondary-color: #ffffff;
  --tertiary-color: #ffffff;
  --highlight-color: rgba(255,255,255,0.6);
  --card-gradient: linear-gradient(180deg, var(--tertiary-color) 30%, var(--secondary-color) 100%);
  --border-radius: 5px;
  background: var(--background-color);
}

.App {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: var(--background-color);
  transition-duration: 2s;
  font-family: "Roboto";
}

.LuckyGem--palette_ {
  --background-color: #ffd1fa;
  --highlight-color: #ffd1fa;
}

.LuckyGem--palette_0 {
  --primary-color: #b50000;
}
.LuckyGem--palette_1 {
  --primary-color: #b57300;
}
.LuckyGem--palette_2 {
  --primary-color: #a78903;
}
.LuckyGem--palette_3 {
  --primary-color: #007100;
}
.LuckyGem--palette_4 {
  --primary-color: #01837f;
  --primary-light-color: #70a4a2;
}
.LuckyGem--palette_5 {
  --primary-color: #2893b0;
}
.LuckyGem--palette_6 {
  --primary-color: #3c3dd4;
}
.LuckyGem--palette_7 {
  --primary-color: #6d2bbd;
}
.LuckyGem--palette_8 {
  --primary-color: #b40258;
}
.LuckyGem--palette_9 {
  --primary-color: #000000;
}

.LuckyGem--palette_cold {
  --primary-light-color: #a8a8d4;
  --primary-dark-color: #391a5e;
  --secondary-color: #da7dff;
  --tertiary-color: #c6e9ff;
  --card-gradient: linear-gradient(120deg, var(--tertiary-color) 30%, var(--secondary-color) 100%);
  --background-color: #e1aaf9;
}
.LuckyGem--palette_hot {
  --primary-light-color: #a6a5a5;
  --primary-dark-color: #000;
  --secondary-color: #6cf6ff;
  --tertiary-color: #ffcaca; 
  --card-gradient: linear-gradient(120deg, var(--tertiary-color) 30%, var(--secondary-color) 100%);
  --background-color: #ffdcdc;
}

.ProfileBar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProfileBar .NearLogo {
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
  flex: 0 0 30px;
  border-radius: 100%;
  fill: var(--primary-color);
}


.Card {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    padding: 30px;
    margin: 10px 10px 10% 10px;
    width: calc(100% - 80px);
    max-width: 350px;
    font-family: 'Roboto';
    border: 0 none;
    border-radius: 20px;
    background: var(--card-gradient);
    box-shadow: 0 2px 5px var(--primary-color);
    transition-duration: 2s;
}

.CardRow {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1 1 auto;
}

.CardCol {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    padding: 5px;
}

.CardCol.small {
    flex: 0 0 auto;
}

.BetButton {
    width: 100%;
    border-radius: var(--border-radius);
}

.CardTitle {
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 10px;
    color: var(--primary-dark-color);
}
.orbe-icon svg {
    fill: rgba(255,255,255,0.8);
}

.Gem--palette_ .orbe-icon svg {
    fill: var(--primary-color);
}

.orbe-icon-question {
    width: 60px;
    margin-top: 7px;
    margin-left: 0px;
    
}

.orbe-icon-bull {
    width: 80px;
    margin-top: 7px;
    margin-left: -4px;
}

.orbe-icon-pig {
    width: 70px;
    margin-top: 5px;
}

.orbe-icon-bear {
    width: 71px;
    margin-top: -2px;
    margin-left: -2px;
}

.orbe-icon-snake {
    width: 50px;
    margin-top: 6px;
}

.orbe-icon-hummingbird {
    width: 65px;
    margin-top: 5px;
    margin-left: 4px;
}

.orbe-icon-dolphin {
    width: 80px;
    margin-top: -3px;
    margin-left: -5px;
}

.orbe-icon-rabbit {
    width: 79px;
    margin-top: 7px;
    margin-left: 3px;
}

.orbe-icon-chameleon {
    width: 80px;
    margin-top: 6px;
}

.orbe-icon-dog {
    width: 70px;
}

.orbe-icon-cat {
    width: 80px;
    margin-left: 4px;
}

.Gem {
    display: inline-block;
}

.orbe {
  position: relative;
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  background-color: var(--primary-color);
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.34);
}

.Gem--palette_ .orbe{
  background-color: #fff;
  box-shadow: none;
  outline: 1px solid var(--primary-color);
}

.orbe-back {
  position: absolute;
  flex: 1 1 100%;
  top: -110%;
  width: 200%;
  height: 200%;
}

.orbe-bubble {
  position: absolute;
  top: 80%;
  left: 25%;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.5);
}




.orbe-back--effect_0 {
  background: radial-gradient(circle, rgba(255,255,255,0) 20%, rgba(255,255,255,0.4) 40%, rgba(250,255,255,0) 60%);
  animation: lightMove 10s infinite alternate linear;
}

.orbe-bubble_0 {
  animation: bubble_0 3s infinite normal ease;
}

.orbe-bubble_0--1 {
  left: 25%;
}

.orbe-bubble_0--2 {
  left: 40%;
  animation-delay: 2.5s;
}

.orbe-bubble_0--3 {
  left: 50%;
  animation-delay: 0.75s;
}

.orbe-bubble_0--4 {
  left: 60%;
  animation-delay: 1.75s;
}

.orbe-bubble_0--5 {
  left: 70%;
  animation-delay: 0.45s;
}

@keyframes lightMove {
  from {
    top: -90%;
    width: 150%;
    height: 200%;
  }
  to {
    top: -60%;
    width: 200%;
    height: 180%;
  }
}

@keyframes bubble_0 {
  0%{
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 0%;
    opacity: 0;
  }
}

.orbe-back--effect_1 {
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: radial-gradient(circle, rgba(255,255,255,0) 30%, rgba(255,255,255,0.7) 100%);
}

.orbe-bubble_1 {
  left: 50%;
  top: 50%;
  animation: bubble_1 0.5s infinite normal ease;
}

.orbe-bubble_1--1 {
  top: 0%;
  left: -5%;
}

.orbe-bubble_1--2 {
  top: 0%;
  left: 60%;
  animation-delay: 0.1s;
}

.orbe-bubble_1--3 {
  top: 50%;
  left: 100%;
  animation-delay: 0.2s;
}

.orbe-bubble_1--4 {
  top: 100%;
  left: 80%;
  animation-delay: 0.3s;
}

.orbe-bubble_1--5 {
  top: 80%;
  left: 0%;
  animation-delay: 0.3s;
}

@keyframes bubble_1 {
  0% {
    left: 50%;
    top: 50%;
    opacity: 0.2;
    transform: scale(1);
  }
  100%{
    opacity: 1;
    transform: scale(1.1);
  }
}


.orbe-back--effect_2 {
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background: radial-gradient(circle, rgba(255,255,255,0) 30%, rgba(255,255,255,0.5) 100%);
}

.orbe-bubble_2 {
  left: 0;
  top: 50%;
  width: 100%;
  height: 10px;
  border-radius: 0;
  animation: bubble_2 5s infinite normal;
}

.orbe-bubble_2--2 {
  animation-delay: 1s;
}
    
.orbe-bubble_2--3 {
  animation-delay: 2s;
}

.orbe-bubble_2--4 {
  animation-delay: 3s;
}

.orbe-bubble_2--5 {
  animation-delay: 4s;
}

@keyframes bubble_2 {
  0% {
    height: 2px;
    opacity: 0.6;
    top: 100%;
  }
  100% {
    top: 55%;
    height: 6px;
    opacity: 0;
  }
}


.orbe-bubble_3 {
  top: 20%;
  left: 20%;
  border-radius: 0;
  opacity: 1;
  width: 60%;
  height: 60%;
  animation: bubble_3 5s infinite linear;
  background: radial-gradient(circle, rgba(255,255,255,0) 20%, rgba(255,255,255,0.15) 100%);
}

.orbe-bubble_3--2 {
  animation: bubble_3_2 5s 3s infinite reverse;
  animation-delay: 2.5s;
  transform: rotate(45deg);
}

.orbe-bubble_3--3 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255,255,055,0) 50%, rgba(255,255,255,0.8) 100%);
}

.orbe-bubble_3--4 {
  opacity: 0;
}
    
.orbe-bubble_3--5 {
      opacity: 0;
}

@keyframes bubble_3 {
  95%{
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bubble_3_2 {
  95%{
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}





.orbe-bubble_4 {
  top: 20%;
  left: 20%;
  border-radius: 100%;
  opacity: 0;
  width: 60%;
  height: 60%;
}

.orbe-bubble_4--1 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background: radial-gradient(circle at center top, rgba(255,255,255,0) 50%, rgba(255,255,255,0.8) 100%);
  animation: effect4_rotate 5s infinite reverse;
}

.orbe-bubble_4--2 {
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  opacity: 0.5;
  background: radial-gradient(circle at center bottom, rgba(255,255,255,0) 50%, rgba(255,255,255,0.8) 100%);
  animation: effect4_rotate 5s infinite;
}

@keyframes effect4_rotate {
  0%{
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.orbe-bubble_5 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  opacity: 0;
}

.orbe-bubble_5--1 {
  background: radial-gradient(circle, rgba(255,255,255,0) 30%, rgba(255,255,255,1) 100%);
  animation: effect5_pulse 2s infinite;
}

.orbe-bubble_5--2 {
  background: radial-gradient(circle, rgba(255,255,255,0) 30%, rgba(255,255,255,1) 100%);
  animation: effect5_pulse 2s 1s infinite;
}

.orbe-bubble_5--3 {
  opacity: 1;
  background: radial-gradient(circle, rgba(255,255,255,0) 60%, rgba(255,255,255,0.6) 100%);
}

@keyframes effect5_pulse {
  0% {
    transform: scale(0);
    opacity: 0.3;
  }
  100% {
    opacity: 0.4;
    transform: scale(2.5);
  }
}

.orbe-bubble_6 {
  top: 0;
  left: 40%;
  width: 10%;
  height: 100%;
  border: 4px solid rgba(255,255,255,0.1);
  background-color: transparent;
  border-radius: 0;
  opacity: 0;
}

.orbe-bubble_6--1 {
  opacity: 1;
  animation: effect6 2s infinite alternate;
}

.orbe-bubble_6--2 {
  opacity: 1;
  animation: effect6 2s infinite alternate-reverse;
}

.orbe-bubble_6--3 {
  opacity:1;
  transform: rotate(45deg);
  animation: effect6 2s 1s infinite alternate;
}

.orbe-bubble_6--4 {
  opacity:1;
  transform: rotate(-45deg);
  animation: effect6 2s 1s infinite alternate-reverse;
}

@keyframes effect6{
  0% {
    top: -50%;
    left: 50%;
    width: 0%;
    height: 200%;
    transform: rotate(0);
    background-color: rgba(255,255,255,0.1);
    opacity: 0.5;
  }
  50%{
    opacity: 1;
    background-color: rgba(255,255,255,0);
  }
  100% {
    top: 50%;
    left: -50%;
    width: 200%;
    height: 0%;
    background-color: rgba(255,255,255,0.1);
    opacity: 0.5;
  }
}


.orbe-bubble_7 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
}

.orbe-bubble_7--1 {
  opacity: 0.2;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
}

.orbe-bubble_7--2 {
  opacity: 0.2;
  top: -0%;
  width: 140%;
  height: 90%;
  left: -20%;
  animation: effect7 5s infinite;
}

.orbe-bubble_7--3 {
  opacity: 0.2;
  top: 10%;
  width: 140%;
  height: 90%;
  left: -20%;
  animation: effect7 5s infinite reverse;
}

@keyframes effect7 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.orbe-bubble_8 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
  --effct8-duration: 5s;
  background: radial-gradient(circle, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0) 100%);
}

.orbe-bubble_8--1 {
  animation: effect8 var(--effct8-duration) infinite;
}

.orbe-bubble_8--2 {
  top: -25%;
  left: -25%;
  animation: effect8 var(--effct8-duration) 1s infinite;
}

.orbe-bubble_8--3 {
  top: 25%;
  left: 25%;
  animation: effect8 var(--effct8-duration) 2s infinite;
}

.orbe-bubble_8--4 {
  top: -25%;
  left: 25%;
  animation: effect8 var(--effct8-duration) 3s infinite;
}

.orbe-bubble_8--5 {
  top: 25%;
  left: -25%;
  animation: effect8 var(--effct8-duration) 4s infinite;
}

@keyframes effect8 {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.4;
  }
  10% {
    opacity: 0;
  }
  15% {
    opacity: 0.4;
  }
  20% {
    opacity: 0;
  }
  25% {
    opacity: 0.4;
  }
  30% {
    opacity: 0;
  }
}

.orbe-back--effect_9 {
  background: radial-gradient(circle, rgba(255,255,255,0) 20%, rgba(255,255,255,0.4) 40%, rgba(250,255,255,0) 60%);
  animation: lightMove9 10s infinite alternate linear;
}

.orbe-bubble_9 {
  animation: bubble_9 3s infinite normal ease;
}

.orbe-bubble_9--1 {
  left: 25%;
}

.orbe-bubble_9--2 {
  left: 40%;
  animation-delay: 2.5s;
}

.orbe-bubble_9--3 {
  left: 50%;
  animation-delay: 0.75s;
}

.orbe-bubble_9--4 {
  left: 60%;
  animation-delay: 1.75s;
}

.orbe-bubble_9--5 {
  left: 70%;
  animation-delay: 0.45s;
}

@keyframes lightMove9 {
  from {
    top: 10%;
    width: 200%;
    height: 200%;
  }
  to {
    top: 15%;
    width: 250%;
    height: 180%;
  }
}

@keyframes bubble_9 {
  0%{
    top: 5%;
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 100%;
    opacity: 0;
  }
}

.BetInput {
    margin: 10px 0 0;
    padding: 5px;
    font-family: 'Roboto';
    font-size: 26px;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
}
.BetInput-number {
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    width: 20px;
    margin: 2px;
    color: var(--primary-color);
    text-align: center;
    font-size: 24px;
}

.BetInput-number:focus-visible {
    outline: 0.5px solid var(--primary-color);
}

.BetInput-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
}


.PrizesList {
    display: flex;
    max-width: 85%;
    margin-bottom: 20px;
    flex-flow: column nowrap;
    color: var(--primary-dark-color);
    font-size: 12px;
}

@media (min-width: 400px) {
    .PrizesList {
        font-size: 16px;
    }
}
@media (min-width: 800px) {
    .PrizesList {
        font-size: 18px;
    }
}

.PrizeList-instructions{
    font-size: 11px;
    margin: 0 0 10px 0;
}

.PrizeList-item {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 100%;
    margin: 2px;
}

.PrizeList-description {
    flex: 0 0 auto;
    margin-right: 10px;
}

.PrizeList-prize {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 2px 4px;
    border-radius: var(--border-radius);
    flex: 1 1 auto;
    text-align: right;
    color: var(--primary-color);
    background-color: var(--highlight-color);
}
.StatusModalBackground {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.7);
}

.StatusModal {
  position: absolute;
  top: 45%;
  left: 50%;
  z-index: 3;
  width: calc(100% - 60px);
  max-width: 600px;
  max-height: 400px;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  transform: translate(-50%, -50%);
  font-family: 'Roboto';
  border: 0 none;
  border-radius: 20px;
  background: var(--card-gradient);
  box-shadow: 0 2px 5px var(--primary-color);
}

.StatusModalBorder {
  margin: -35%;
  padding: 30px;
  width: 170%;
  height: 180%;
  opacity: 0.7;
  background-color: rgb(255, 255, 255);
}
.StatusModalBorder--shine {
  animation: rotateGrad 2s infinite linear;
  background: linear-gradient(0deg, rgba(226, 3, 3, 1) 0%, rgba(230, 146, 2, 1) 10%, rgba(222, 182, 2, 1) 21%, rgba(4, 230, 4, 1) 32%, rgba(3, 228, 221, 1) 44%, rgba(54, 196, 235, 1) 56%, rgba(72, 73, 245, 1) 71%, rgba(142, 59, 242, 1) 81%, rgba(237, 2, 116, 1) 91%, rgba(236, 2, 115, 1) 100%);
}

.StatusModalContent {
  position: absolute;
  top: 0;
  left: 0;
  margin: 2px;
  padding: 20px;
  text-align: center;
  height: calc(100% - 44px);
  width: calc(100% - 44px);
  background-color: #fff;
  border-radius: 20px;
}

.StatusModalHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 0 20px 10px;
  font-size: 20px;
  font-weight: 550;
}

.StatusModalBody {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-size: 16px;
  line-height: 28px;
}
.StatusModalBody p {
  margin: 5px;
}
.StatusModalBody .orbe {
  margin: 0 0 5px;
}

.StatusModal-drawnedBet {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: flex-start;
  width: 120px;
}

.StatusModal-drawnedBetText {
  flex: 1 1 50%;
  text-align: right;
  padding: 3px;
}
.StatusModal-drawnedBetNumber {
  flex: 0 0 auto;
  padding: 3px;
}
.StatusModal-betNumber {
  display: inline-block;
}
.StatusModal-betNumber--wrong {
  background-color: rgba(226, 3, 3, 0.6);
}

.StatusModalClose {
  position: absolute;
  top: 6ṕx;
  right: 10px;
  justify-self: flex-end;
  align-self: center;
  padding: 10px;
  color: #000;
  font-size: 20px;
  background-color: transparent;
  border-radius: 100%;
  cursor: pointer;
}

@keyframes rotateGrad {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Instructions {
    flex: 1 1 auto;
    width: 100%;
    padding: 5px 12px;
    border-radius: var(--border-radius);
    color: var(--primary-color);
    border: none;
    background-color: var(--highlight-color);
    font-weight: 500;
    font-size: 14px;
}

@media (min-width: 400px) {
    .Instructions {
        font-size: 16px;
    }
}
@media (min-width: 800px) {
    .PrizesList {
        font-size: 18px;
    }
}

.Intructions--right {
    text-align: right;
    font-weight: 700;
}
.BetButtonX {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    border-radius: var(--border-radius);
    width: 100%;
    height: 30px;
    cursor: pointer;
    background: var(--primary-color);
    overflow: hidden;
    box-shadow: 0 0 12px rgba(255,255,255,1);
    border: 2px solid var(--primary-color);
}
.BetButtonX--jump {
    animation: jump 0.3s 1 linear;
}

.BetButtonX-border {
    margin: -60% -10%;
    padding: 30px;
    width: 100%;
    height: 220px;
    opacity: 1;
    background-color: transparent;
    flex: 1 1 100%;
}

.BetButtonX-border--shine {
    position: absolute;
    left: 120%;
    opacity: 0.5;
    background-color: #fff;
    width: 100%;
    height: 100%;
    transform: skew(5deg);
    animation: shine .2s 1 linear;
}

.BetButtonX-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    height: 100%;
    border-radius: var(--border-radius);
    flex: 1 1 auto;
}

@keyframes jump {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
  }

  @keyframes shine {
    0%{ left: -100%;}
    100%{ left: 120%; }
  }
.Footer {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    background-color: rgba(255,255,255, 0.6);
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    font-size: 11px;
}

@media (min-width: 400px) {
    .Footer {
        font-size: 13px;
    }
}
.Footer-content {
    flex: 0 0 auto;
}
