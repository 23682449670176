.BetButtonX {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
    border-radius: var(--border-radius);
    width: 100%;
    height: 30px;
    cursor: pointer;
    background: var(--primary-color);
    overflow: hidden;
    box-shadow: 0 0 12px rgba(255,255,255,1);
    border: 2px solid var(--primary-color);
}
.BetButtonX--jump {
    animation: jump 0.3s 1 linear;
}

.BetButtonX-border {
    margin: -60% -10%;
    padding: 30px;
    width: 100%;
    height: 220px;
    opacity: 1;
    background-color: transparent;
    flex: 1 1 100%;
}

.BetButtonX-border--shine {
    position: absolute;
    left: 120%;
    opacity: 0.5;
    background-color: #fff;
    width: 100%;
    height: 100%;
    transform: skew(5deg);
    animation: shine .2s 1 linear;
}

.BetButtonX-content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    height: 100%;
    border-radius: var(--border-radius);
    flex: 1 1 auto;
}

@keyframes jump {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
  }

  @keyframes shine {
    0%{ left: -100%;}
    100%{ left: 120%; }
  }