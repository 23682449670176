
.Instructions {
    flex: 1 1 auto;
    width: 100%;
    padding: 5px 12px;
    border-radius: var(--border-radius);
    color: var(--primary-color);
    border: none;
    background-color: var(--highlight-color);
    font-weight: 500;
    font-size: 14px;
}

@media (min-width: 400px) {
    .Instructions {
        font-size: 16px;
    }
}
@media (min-width: 800px) {
    .PrizesList {
        font-size: 18px;
    }
}

.Intructions--right {
    text-align: right;
    font-weight: 700;
}